import * as React from "react";
import { User, Side, CallPointState, Call } from "compass.js";
import ringing from "src/assets/sounds/ringing.mp3";
import { getUserSide, shouldShowCallForUser } from "src/utils/call";

interface AudioNotificationsProps {
  calls: Call[];
  authUser: User;
  callsMetadata: Record<string, { isEnded: boolean }>;
}

const AudioNotifications: React.FC<AudioNotificationsProps> = ({
  calls,
  authUser,
  callsMetadata,
}) => {
  const audioRef = React.useRef<HTMLAudioElement | null>(null);

  const ringingCalls = React.useMemo(() => {
    return calls.filter((call) => {
      const selfSide = getUserSide(call, authUser);
      return (
        shouldShowCallForUser(call, authUser) &&
        selfSide === Side.destination &&
        call.destination.state === CallPointState.ringing &&
        !callsMetadata[call.id].isEnded
      );
    });
  }, [authUser, calls, callsMetadata]);

  React.useEffect(() => {
    if (!audioRef.current) return;

    if (ringingCalls.length > 0) {
      audioRef.current.play().catch(console.error);
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [ringingCalls]);

  return <audio ref={audioRef} src={ringing} loop />;
};

export default AudioNotifications;
