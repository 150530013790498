import * as React from "react";
import Home from "./containers/Home/Home";
import Login from "./containers/Login/Login";
import Loading from "./containers/Loading/Loading";
import ContactsOverview from "./containers/ContactsOverview/ContactsOverview";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { authInitialize } from "./store/actions/auth";
import { IRootState } from "./store/reducers";
import { IntlProvider } from "react-intl";
import * as enMessages from "./translations/locales/en.json";
import * as nlMessages from "./translations/locales/nl.json";
import { IHomePageParams, NavigationPage } from "./store/reducers/navigation";
import { navigationSet } from "./store/actions/navigation";
import Notifications from "./containers/Notifications/Notifications";
import { tickIntervalDestroy } from "./store/actions/tickInterval";
import { CallForwardType } from "./store/preferences";
import { windowUpdateInit } from "./store/actions/window";
import { dialNumber, notificationShow } from "./store/actions";
import { isElectron, usePrevious } from "./utils";
import { DesktopEventType } from "src/utils";
import { handleError } from "./utils/errorHandler";
import {
  GlobalAlert,
  IGlobalAlertProps,
} from "./components/GlobalAlert/GlobalAlert";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { INotification } from "./store/reducers/notifications";
import { OnboardingStepId } from "./utils/OnboardingStep";
import { trackPageView } from "./utils/track";
import { wrapOnboarding } from "./utils/onboarding";
import { ReceiveCalls } from "compass.js";
import AwayStatusNotification from "./components/AwayStatusNotification/AwayStatusNotification";
import AudioNotifications from "./components/AudioNotifications/AudioNotifications";
import Settings from "./containers/Settings/Settings";

import { getContactByNumber } from "./utils/contact";
import { VOICEMAIL_NUMBER } from "./utils/consts";
import { useCallback, useEffect, useRef } from "react";
import { IpcRendererEvent } from "electron";
import { store } from "./store";

import { fetchMicrosoftContacts } from "src/store/actions/contacts";
import { useIsSignedIn } from "@microsoft/mgt-react";
import { createSelector } from "reselect";
import { DialerWebrtcContext } from "./providers/DialerWebrtcContext";
import { dialNumberWebrtc } from "./store/actions/calls";
import CallNotifications from "./components/Calls/CallNotifications/CallNotifications";

const appDataSelector = createSelector(
  (state: IRootState) => state.navigation,
  (state: IRootState) => state.auth,
  (state: IRootState) => state.preferences,
  (state: IRootState) => state.window,
  (state: IRootState) => state.contacts,
  (state: IRootState) => state.queues,
  (state: IRootState) => state.calls.items,
  (state: IRootState) => state.calls.callsMetadata,
  (
    navigation,
    auth,
    preferences,
    window,
    contacts,
    queues,
    calls,
    callsMetadata
  ) => {
    return {
      navigationPage: navigation.page,
      navigationParams: navigation.params as IHomePageParams,
      isAuthenticated: auth.isAuthenticated,
      userPreferences: preferences.user,
      authInitializationInProgress: auth.initializationInProgress,
      online: window.online,
      onboardingMode: auth.onboardingMode,
      isConnected: !!auth.connection,
      apiVersion: auth.apiVersion,
      identity: preferences.identity,
      compassNumbersMap: contacts.compassItemsNumbersMap,
      addressBookNumbersMap: contacts.addressBookItemsNumbersMap,
      compassItems: contacts.compassItems,
      addressBookItems: contacts.addressBookItems,
      v2GlobalPause: queues.v2GlobalPause,
      user: auth.user,
      calls,
      callsMetadata,
      authUser: auth.user,
      phone: auth.phone,
    };
  }
);
const App = () => {
  const {
    navigationPage,
    isAuthenticated,
    userPreferences,
    isConnected,
    identity,
    online,
    compassNumbersMap,
    addressBookNumbersMap,
    compassItems,
    addressBookItems,
    navigationParams,
    authInitializationInProgress,
    onboardingMode,
    apiVersion,
    v2GlobalPause,
    user,
    authUser,
    calls,
    callsMetadata,
    phone,
  } = useSelector(appDataSelector);

  const dispatch = useDispatch();
  const { dialer } = React.useContext(DialerWebrtcContext);
  let currentTrackPageRef = useRef<string>();
  const [isMsSignedIn] = useIsSignedIn();
  const isApiAbove2 = !!apiVersion && apiVersion >= 3;
  const { receiveCalls, isWrapUp } = React.useMemo(() => {
    let receiveCalls: ReceiveCalls | undefined;
    let isWrapUp: boolean = false;
    if (user) {
      receiveCalls = isApiAbove2
        ? user.status.receiveCalls
        : v2GlobalPause
        ? ReceiveCalls.onlyDirect
        : ReceiveCalls.all;
      isWrapUp = isApiAbove2 && !!user.status.wrapupState;
    }
    return { receiveCalls, isWrapUp };
  }, [isApiAbove2, user, v2GlobalPause]);

  const receiveCallsPrev = usePrevious(receiveCalls);
  const isWrapUpPrev = usePrevious(isWrapUp);
  const onlinePrev = usePrevious(online);
  const onNavigationSet = useCallback(
    (page: NavigationPage, params?: any) =>
      dispatch(navigationSet(page, params)),
    [dispatch]
  );

  const onNotificationShow = useCallback(
    (
      notification:
        | INotification
        | Pick<
            INotification,
            "message" | "level" | "autoDismiss" | "dismissable"
          >
    ) => dispatch(notificationShow(notification)),
    [dispatch]
  );

  const onAuthInitialize = useCallback(async () => {
    return dispatch<any>(authInitialize());
  }, [dispatch]);

  const dialPhoneNumber = useCallback(
    (destination: string) => {
      if (phone?.isBridgePhone) {
        return dispatch<any>(dialNumberWebrtc(destination, dialer));
      } else {
        return dispatch<any>(dialNumber(destination)).catch(handleError);
      }
    },
    [dialer, dispatch, phone?.isBridgePhone]
  );
  const onFetchMsContacts = useCallback(async () => {
    return dispatch<any>(fetchMicrosoftContacts());
  }, [dispatch]);

  const initializeConnection = useCallback(() => {
    onAuthInitialize().then(({ authenticated, connected }) => {
      if (isElectron()) {
        const isConnected = !!store.getState().auth.connection;
        if (window.electronAPI) {
          window.electronAPI.ipcRenderer.send(
            DesktopEventType.setupTelProtocol
          );
          window.electronAPI.onTelEvent((e: IpcRendererEvent, data: any) => {
            if (!isConnected) {
              return;
            }
            data.number &&
              dialPhoneNumber(String(data.number)).catch(handleError);
          });
        }
      }
      if (authenticated) {
        const currentUserPreferences = store.getState().preferences.user;
        const params: IHomePageParams = {
          list: currentUserPreferences.defaultHomeList,
          dialerActive: false,
          detailsOpened: false,
        };

        if (
          new URLSearchParams(window.location.search).get("contactsOverview") &&
          connected
        ) {
          onNavigationSet(NavigationPage.contactsOverview);
        } else {
          onNavigationSet(NavigationPage.home, params);
        }
      } else {
        onNavigationSet(NavigationPage.login);
      }
    });
  }, [onAuthInitialize, dialPhoneNumber, onNavigationSet]);

  useEffect(() => {
    if (isAuthenticated && receiveCallsPrev !== receiveCalls) {
      let notificationMessage: string = "";
      let notificationLevel: INotification["level"] = "info";
      switch (receiveCalls) {
        case ReceiveCalls.none:
          notificationMessage =
            "You set your status to No calls. You won't receive any calls until you manually change it.";
          break;
        case ReceiveCalls.onlyDirect:
          if (!isWrapUp) {
            notificationMessage =
              "You set your status to No queue calls. You will not receive queue calls until you manually change it.";
          }
          break;
        case ReceiveCalls.all:
          if (!isWrapUpPrev) {
            notificationMessage =
              "You set your status to Available. You will receive all calls.";
            notificationLevel = "success";
          }
          break;
      }
      if (notificationMessage) {
        onNotificationShow({
          uid: `user-status-notification`,
          message: notificationMessage,
          level: notificationLevel,
          autoDismiss: 3000,
          dismissable: true,
        });
      }
    }
    if (!isAuthenticated && navigationPage !== NavigationPage.login) {
      onNavigationSet(NavigationPage.login);
    }

    if (
      !isConnected &&
      online &&
      !onlinePrev &&
      !authInitializationInProgress
    ) {
      initializeConnection();
    }
  }, [
    authInitializationInProgress,
    initializeConnection,
    isAuthenticated,
    isConnected,
    isWrapUp,
    isWrapUpPrev,
    navigationPage,
    onNavigationSet,
    onNotificationShow,
    online,
    onlinePrev,
    receiveCalls,
    receiveCallsPrev,
  ]);

  useEffect(() => {
    if (!isAuthenticated && navigationPage !== NavigationPage.login) {
      dispatch(navigationSet(NavigationPage.login));
    }
  }, [dispatch, isAuthenticated, navigationPage]);

  useEffect(() => {
    windowUpdateInit();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(tickIntervalDestroy());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isMsSignedIn) {
      onFetchMsContacts();
    }
  }, [isMsSignedIn, onFetchMsContacts]);

  const $getGlobalAlert = (): JSX.Element | null => {
    let globalAlertProps: IGlobalAlertProps | null = null;

    if (!online) {
      globalAlertProps = {
        message:
          "No internet connection. Please reconnect to be able to use Bridge.",
        level: "danger",
        icon: faExclamationCircle,
      };
    }

    if (
      navigationPage === NavigationPage.home &&
      identity &&
      !!identity[CallForwardType.always]
    ) {
      let numberName = "another number";
      if (identity[CallForwardType.always] === VOICEMAIL_NUMBER) {
        numberName = "voicemail";
      } else {
        const contact = getContactByNumber(
          identity[CallForwardType.always],
          compassNumbersMap,
          addressBookNumbersMap,
          compassItems,
          addressBookItems
        );

        if (contact && contact.name) {
          numberName = contact.name;
        }
      }

      globalAlertProps = {
        message: `You are not receiving any calls because they are forwarded to ${numberName}. `,
        level: "info",
        icon: faExclamationTriangle,
        action: {
          text: "Manage",
          onClick: () => {
            onNavigationSet(NavigationPage.settings, {
              highlightAlwaysForwarding: true,
            });
          },
        },
      };
    }

    return globalAlertProps ? <GlobalAlert {...globalAlertProps} /> : null;
  };

  const trackPage = () => {
    let trackPage: string | undefined;

    switch (navigationPage) {
      case NavigationPage.home:
        trackPage = `${navigationPage}-${navigationParams.list}`;
        break;
      default:
        trackPage = navigationPage;
    }

    if (!trackPage || trackPage === currentTrackPageRef.current) {
      return;
    }

    currentTrackPageRef.current = trackPage;
    trackPageView(trackPage);
  };

  const getLocaleInfo = (): { locale: string; messages: any } => {
    const lang = new URLSearchParams(window.location.search).get("lang");
    let locale;
    let messages;

    switch (lang) {
      case "nl":
        locale = "nl";
        messages = nlMessages.default;
        break;
      default:
        locale = "en";
        messages = enMessages.default;
    }

    return {
      locale,
      messages,
    };
  };

  const localeInfo = getLocaleInfo();
  const cssClasses = ["app", `app--${userPreferences.viewMode}`];
  const $globalAlert = $getGlobalAlert();

  if ($globalAlert) {
    cssClasses.push("app--has-global-alert");
  }

  let content: React.ReactNode = null;

  if (!navigationPage || authInitializationInProgress) {
    content = <Loading />;
  } else {
    switch (navigationPage) {
      case NavigationPage.home:
        content = <Home onReloadClick={initializeConnection} />;
        break;
      case NavigationPage.login:
        content = <Login />;
        break;
      case NavigationPage.contactsOverview:
        cssClasses.push("app--contacts-overview");
        content = <ContactsOverview />;
        break;
      case NavigationPage.settings:
        content = <Settings />;
    }

    if (!onboardingMode) {
      trackPage();
    }
  }

  const $onboardingHook = <div className="app__onboarding-tooltip-hook" />;

  return (
    <IntlProvider locale={localeInfo.locale} messages={localeInfo.messages}>
      <>
        {$globalAlert}
        <div className={cssClasses.join(" ")}>
          {content}
          {apiVersion && apiVersion >= 3 ? <AwayStatusNotification /> : null}
          <Notifications />
          {authUser && isAuthenticated && (
            <>
              <AudioNotifications
                calls={calls}
                authUser={authUser}
                callsMetadata={callsMetadata}
              />
              <CallNotifications />
            </>
          )}
          {wrapOnboarding($onboardingHook, [
            OnboardingStepId.finish,
            OnboardingStepId.finishNoPremium,
            OnboardingStepId.finishGuest,
            OnboardingStepId.endNoPremium,
            OnboardingStepId.welcome,
            OnboardingStepId.welcomeGuest,
          ])}
        </div>
      </>
    </IntlProvider>
  );
};

export default App;
