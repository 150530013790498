import * as React from "react";
import CallItem from "./CallItem/CallItem";
import "./Calls.scss";
import { IRootState } from "src/store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { User } from "compass.js";
import { HorizontalScroll } from "src/hoc/HorizontalScroll/HorizontalScroll";
import TransferModal from "src/components/TransferModal/TransferModal";
import FlipMove from "react-flip-move";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { trackEvent, TrackCategory, TrackAction } from "src/utils/track";
import { shouldShowCallForUser } from "src/utils/call";
import { sortBy, sortNumbersComparator } from "src/utils";
import { wrapOnboarding } from "src/utils/onboarding";
import Modal from "src/components/UI/Modal/Modal";
import { resetDesiredTransferCall } from "src/store/actions/calls";

import AttendedTransferModal from "../AttendedTransferModal/AttendedTransferModal";
import { Fragment, useEffect } from "react";
import { createSelector } from "reselect";

const selectCalls = (state: IRootState) => state.calls;
const selectAuth = (state: IRootState) => state.auth;

const appDataSelector = createSelector(
  [selectCalls, selectAuth],
  (calls, auth) => {
    const callsMetadata = calls.callsMetadata;
    const desiredTransferCallId = calls.desiredTransferCallId;
    const callsList = calls.items.filter((item) =>
      shouldShowCallForUser(item, auth.user as User)
    );
    const onboardingMode = auth.onboardingMode;

    return {
      callsMetadata,
      desiredTransferCallId,
      calls: callsList,
      onboardingMode,
    };
  }
);
const Calls: React.FC = () => {
  const { callsMetadata, desiredTransferCallId, calls, onboardingMode } =
    useSelector(appDataSelector);

  const dispatch = useDispatch();
  sortBy(
    calls,
    (item) => {
      return callsMetadata[item.id].created;
    },
    sortNumbersComparator
  );

  let attendedTransferConsultationCallId: string | undefined;
  let attendedTransferMainCallTitle: string | undefined;

  if (desiredTransferCallId) {
    const desiredTransferCallMetadata = callsMetadata[desiredTransferCallId];
    if (
      desiredTransferCallMetadata &&
      desiredTransferCallMetadata.attendedTransferConsultationLegId
    ) {
      attendedTransferConsultationCallId =
        desiredTransferCallMetadata.attendedTransferConsultationLegId;
      attendedTransferMainCallTitle =
        desiredTransferCallMetadata.lastConnectedTitle;
    }
  }

  const onResetDesiredTransferCall = () => dispatch(resetDesiredTransferCall());

  const callsItemsCssClasses = ["calls__items"];
  if (!calls || !calls.length) {
    callsItemsCssClasses.push("calls__items--no-calls");
  }
  const $calls = (
    <div className="calls">
      <div className="calls__no-calls">
        <p>You currently have no calls</p>
      </div>

      <HorizontalScroll>
        <FlipMove enterAnimation="none" leaveAnimation="none">
          <div className={callsItemsCssClasses.join(" ")}>
            {calls.map((call) => {
              return (
                <Fragment key={call.id}>
                  <CallItem callId={call.id} />
                </Fragment>
              );
            })}
          </div>
        </FlipMove>
      </HorizontalScroll>
    </div>
  );
  const showTransferModal =
    !!desiredTransferCallId && !attendedTransferConsultationCallId;
  const showAttendedTransferModal =
    !!desiredTransferCallId && !!attendedTransferConsultationCallId;

  useEffect(() => {
    if (calls.length) {
      trackEvent(
        TrackCategory.calls,
        TrackAction.callsNumber,
        undefined,
        calls.length.toString()
      );
    }
  }, [calls.length]);

  return (
    <>
      {wrapOnboarding($calls, OnboardingStepId.sectionCalls)}
      <Modal
        isOpen={showTransferModal}
        // NOTE: #678
        disableAnimation={onboardingMode}
        showCloseBtn={false}
        disableOverlayClose={onboardingMode}
        onRequestClose={onResetDesiredTransferCall}
        className="transfer-modal"
      >
        <TransferModal />
      </Modal>
      {showAttendedTransferModal && (
        <Modal
          isOpen={showAttendedTransferModal}
          showCloseBtn={false}
          showMinimizeBtn={true}
          disableAnimation={true}
          title={`Transfer ${attendedTransferMainCallTitle}`}
        >
          <AttendedTransferModal
            mainCallId={desiredTransferCallId!}
            consultationCallId={attendedTransferConsultationCallId!}
          />
        </Modal>
      )}
    </>
  );
};

export default Calls;
