import { User } from "compass.js";
import { IContact, ContactType } from "src/store/reducers/contacts";
import { store } from "src/store";
import { stringContains, sortByProperty, sortIgnoreCaseComparator } from ".";
import { getUserStatusInfo, UserStatus } from "./user";
import stable from "stable";

export const compassUserToContact = (user: User): IContact => {
  let phones: Array<{ label: string; value: string }> = [];
  const emails: Array<{ label: string; value: string }> = [];
  if (user.extensions && user.extensions.length) {
    phones = user.extensions.map((phone) => {
      return {
        label: "Internal",
        value: phone,
      };
    });
  }
  if (user.contact) {
    emails.push({
      label: "Internal",
      value: user.contact,
    });
  }
  return {
    id: user.id,
    type: ContactType.compass,
    name: user.name,
    phones,
    emails,
    user,
  };
};

export const compassUsersToContacts = (users: { [key: string]: User }) => {
  const items: { [key: string]: IContact } = {};
  const numbersMap: { [key: string]: IContact["id"] } = {};
  Object.keys(users).forEach((userId) => {
    const contact = compassUserToContact(users[userId]);
    items[userId] = contact;
    contact.phones.forEach((phone) => {
      numbersMap[phone.value.replace(/\D/g, "")] = contact.id;
    });
  });
  return { items, numbersMap };
};

export const filterContacts = (contactList: IContact[], queryStr: string) => {
  const company = store.getState().auth.company;
  let companyName = "";
  if (company) {
    companyName = company.name;
  }
  return contactList.filter((contact) => {
    const searchStrArr = [contact.name];
    contact.phones.forEach((phoneItem) => {
      searchStrArr.push(phoneItem.value);
    });
    if (contact.type === ContactType.compass) {
      searchStrArr.push(companyName);
      if (contact.user) {
        const userStatusInfo = getUserStatusInfo(contact.user);
        switch (userStatusInfo.userStatus) {
          case UserStatus.inCall:
            if (queryStr.includes("in call")) {
              return true;
            }
            break;

          case UserStatus.available:
            if (queryStr.includes("available")) {
              return true;
            }
            break;
          case UserStatus.loggedOut:
            if (queryStr.includes("logged out")) {
              return true;
            }
        }
      }
    }
    return stringContains(searchStrArr.join(" "), queryStr);
  });
};

/**
 * Sort contacts list by with priority:
 * 1. Pinned
 * 2. Available status
 * 3. Alphabet
 * @param contacts Contacts list
 */
export const sortContacts = (
  contacts: IContact[],
  pinnedContactsParams?: string[]
) => {
  const pinnedContacts =
    pinnedContactsParams || store.getState().contacts.pinned;

  // First, sort alphabetically for all contacts
  let sortedContacts: IContact[] = contacts.slice();
  sortedContacts = sortByProperty<IContact, string>(
    sortedContacts,
    "name",
    sortIgnoreCaseComparator
  );

  // Prioritize pinned contacts
  sortedContacts = stable(sortedContacts, (a: IContact, b: IContact) => {
    const aPinned = !!pinnedContacts.find((item) => item === a.id);
    const bPinned = !!pinnedContacts.find((item) => item === b.id);
    if (!aPinned && bPinned) {
      return 1;
    } else if (aPinned && !bPinned) {
      return -1;
    }
    return 0;
  });

  // Prioritize contacts of type `ContactType.compass` over `ContactType.addressBook`
  sortedContacts = stable(sortedContacts, (a: IContact, b: IContact) => {
    if (a.type === ContactType.compass && b.type !== ContactType.compass) {
      return -1;
    } else if (
      a.type !== ContactType.compass &&
      b.type === ContactType.compass
    ) {
      return 1;
    }
    return 0;
  });

  // Additional sort by availability status for `ContactType.compass`
  sortedContacts = stable(sortedContacts, (a: IContact, b: IContact) => {
    const aUserActive =
      a.type === ContactType.compass &&
      a.user &&
      [UserStatus.available, UserStatus.inCall].includes(
        getUserStatusInfo(a.user).userStatus
      );
    const bUserActive =
      b.type === ContactType.compass &&
      b.user &&
      [UserStatus.available, UserStatus.inCall].includes(
        getUserStatusInfo(b.user).userStatus
      );
    if (!aUserActive && bUserActive) {
      return 1;
    } else if (aUserActive && !bUserActive) {
      return -1;
    }
    return 0;
  });

  // Finally, prioritize pinned contacts within `ContactType.compass`
  sortedContacts = stable(sortedContacts, (a: IContact, b: IContact) => {
    const aPinned = !!pinnedContacts.find((item) => item === a.id);
    const bPinned = !!pinnedContacts.find((item) => item === b.id);
    if (!aPinned && bPinned) {
      return 1;
    } else if (aPinned && !bPinned) {
      return -1;
    }
    return 0;
  });

  return sortedContacts;
};

export const getContactByNumber = (
  contactNumber: string,
  compassItemsNumbersMap: {
    [key: string]: string;
  },
  addressBookItemsNumbersMap: {
    [key: string]: string;
  },
  compassItems: {
    [key: string]: IContact;
  },
  addressBookItems: {
    [key: string]: IContact;
  }
) => {
  const contactId: IContact["id"] =
    compassItemsNumbersMap[contactNumber] ||
    addressBookItemsNumbersMap[contactNumber];

  if (!contactId) {
    return null;
  }
  return compassItems[contactId] || addressBookItems[contactId];
};
