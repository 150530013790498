import * as React from "react";
import Navbar from "src/components/Navbar/Navbar";
import Calls from "src/components/Calls/Calls";
import BottomView from "src/components/BottomView/BottomView";
import OnboardingModal from "src/components/OnboardingModal/OnboardingModal";
import LoginPhoneModal, {
  LoginPhoneModalType,
} from "src/components/LoginPhoneModal/LoginPhoneModal";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import "./Home.scss";
import Button from "src/components/UI/Button/Button";
import { BridgeColor } from "src/utils/consts";
import {
  showLoginPhoneModal,
  showOnboardingModal,
} from "src/store/actions/auth";
import { userStorage } from "src/utils/userStorage";
import { ONBOARDING_MODAL_SHOWN_KEY } from "src/utils/consts";

import { useEffect } from "react";
import { createSelector } from "reselect";

const appDataSelector = createSelector(
  [
    (state: IRootState) => state.auth.connection,
    (state: IRootState) => state.auth.userStatus,
    (state: IRootState) => state.auth.onboardingMode,
    (state: IRootState) => state.auth.onboardingType,
    (state: IRootState) => state.auth.isAuthenticated,
  ],
  (
    connection,
    userStatus,
    onboardingMode,
    onboardingType,
    isAuthenticated
  ) => ({
    isConnected: !!connection,
    hasPhone: !!userStatus && !!userStatus.phone,
    onboardingMode,
    onboardingType,
    isAuthenticated,
  })
);
const Home: React.FC<{
  onReloadClick?: () => void;
}> = ({ onReloadClick }) => {
  const { isConnected, hasPhone, onboardingMode, isAuthenticated } =
    useSelector(appDataSelector);

  const dispatch = useDispatch();
  const onShowLoginPhoneModal = React.useCallback(
    (modalType: LoginPhoneModalType) =>
      dispatch(showLoginPhoneModal(modalType)),
    [dispatch]
  );
  const onShowOnboardingModal = React.useCallback(
    () => dispatch(showOnboardingModal()),
    [dispatch]
  );

  useEffect(() => {
    if (onboardingMode || !isAuthenticated) {
      return;
    }

    Promise.all([userStorage.getItem(ONBOARDING_MODAL_SHOWN_KEY)])
      .then(([onboardingShown]) => {
        if (!onboardingShown) {
          onShowOnboardingModal();
        } else if (!hasPhone) {
          onShowLoginPhoneModal(LoginPhoneModalType.login);
        }
      })
      .catch((error) => {
        console.warn("Storage not accessible:", error);
      });
  }, [
    hasPhone,
    isAuthenticated,
    onShowLoginPhoneModal,
    onShowOnboardingModal,
    onboardingMode,
  ]);

  return (
    <>
      <Navbar />
      {isConnected ? (
        <>
          <Calls />
          <BottomView />
          <OnboardingModal />
          {!onboardingMode && <LoginPhoneModal />}
        </>
      ) : (
        <div className="home__error">
          <div className="home__error-message">Cannot connect to Bridge</div>
          <div className="home__error-btn">
            <Button color={BridgeColor.prim500} onClick={onReloadClick}>
              Try again
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
