import * as React from "react";
import SearchInput from "src/components/UI/SearchInput/SearchInput";
import Dialer, { DialerType } from "src/components/UI/Dialer/Dialer";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faTh } from "@fortawesome/pro-solid-svg-icons/faTh";
import "./TransferModal.scss";
import { IContact, ContactType } from "src/store/reducers/contacts";
import { IRootState } from "src/store/reducers";
import {
  User,
  Connection,
  OtherSide,
  UserCallPoint,
  CallPointType,
  Call,
  Side,
  CallState,
} from "compass.js";
import { notificationShow } from "src/store/actions";
import {
  resetDesiredTransferCall,
  redirectCall,
  startAttendedTransfer,
  mergeCalls,
  registerAttendedTransfer,
  cancelAttendedTransferDestination,
} from "src/store/actions/calls";
import { useDispatch, useSelector } from "react-redux";
import {
  sortByProperty,
  sortIgnoreCaseComparator,
  cssSafeStr,
  stringContains,
} from "src/utils";
import {
  canAuthenticatedUserUseFeature,
  getUserStatusInfo,
  UserStatus,
} from "src/utils/user";
import { getUserSide } from "src/utils/call";
import { handleError } from "src/utils/errorHandler";
import { PhoneCapability, UserFeature } from "src/store/reducers/auth";
import { sortContacts, filterContacts } from "src/utils/contact";
import { TrackCategory, TrackAction } from "src/utils/track";
import Infinite from "react-infinite";
import {
  COMPACT_LIST_ITEM_HEIGHT,
  //INFINITE_LIST_BREAKPOINT,
  BridgeColor,
} from "src/utils/consts";
import { ListEmptyMsg } from "src/components/UI/List/";
import TransferDestinationItem from "./TransferDestinationItem/TransferDestinationItem";
import { wrapOnboarding } from "src/utils/onboarding";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { useForceUpdate } from "src/utils/hooks";
import { useEffect, useRef, useState } from "react";
import { INotification } from "src/store/reducers/notifications";
import { createSelector } from "reselect";
const TRACK_CATEGORY = TrackCategory.transferModal;
const selectCallsItems = (state: IRootState) => state.calls.items;
const selectDesiredTransferCallId = (state: IRootState) =>
  state.calls.desiredTransferCallId;
const selectCompassItems = (state: IRootState) => state.contacts.compassItems;
const selectAddressBookItems = (state: IRootState) =>
  state.contacts.addressBookItems;
const selectAuthUser = (state: IRootState) => state.auth.user as User;
const selectAuthPhone = (state: IRootState) => state.auth.phone;
const selectContactsPinned = (state: IRootState) => state.contacts.pinned;
const selectUsers = (state: IRootState) =>
  (state.auth.connection as Connection).model.users;
const selectCallsIsLoading = (state: IRootState) =>
  !!state.calls.actionsInProgress;
const selectCallsMetadata = (state: IRootState) => state.calls.callsMetadata;
const selectOnboardingMode = (state: IRootState) => state.auth.onboardingMode;
const appDataSelector = createSelector(
  [
    selectCallsItems,
    selectDesiredTransferCallId,
    selectCompassItems,
    selectAddressBookItems,
    selectAuthUser,
    selectAuthPhone,
    selectContactsPinned,
    selectUsers,
    selectCallsIsLoading,
    selectCallsMetadata,
    selectOnboardingMode,
  ],
  (
    callsItems,
    desiredTransferCallId,
    compassItems,
    addressBookItems,
    authUser,
    authphone,
    contactsPinned,
    users,
    callsIsLoading,
    callsMetadata,
    onboardingMode
  ) => ({
    callsItems,
    desiredTransferCallId,
    compassItems,
    addressBookItems,
    authUser,
    authphone,
    contactsPinned,
    users,
    user: authUser,
    callsIsLoading,
    callsMetadata,
    onboardingMode,
  })
);

const TransferModal: React.FC = () => {
  const {
    callsItems,
    desiredTransferCallId,
    compassItems,
    addressBookItems,
    authUser,
    authphone,
    contactsPinned,
    users,
    user,
    callsIsLoading,
    callsMetadata,
    onboardingMode,
  } = useSelector(appDataSelector);
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [searchQuery, setSearchQuery] = useState("");
  const [showDialer, setShowDialer] = useState(false);
  const [activeItem, setActiveItem] = useState<string>();

  const [waitingForConsultationCall, setWaitingForConsultationCall] =
    useState(false);
  const $contentWrap = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // To render the infinite list properly, we need to render
    // the wrapper element first to get the exact height.
    forceUpdate();
  }, [forceUpdate]);

  const transferCall = callsItems.find(
    (item) => item.id === desiredTransferCallId
  );
  let transferCallUser: User;
  if (transferCall) {
    const selfSide = getUserSide(transferCall, authUser);
    if (selfSide !== null) {
      const otherCallPoint = transferCall.getEndpoint(OtherSide(selfSide));
      if (otherCallPoint.type === CallPointType.user) {
        transferCallUser = (otherCallPoint as UserCallPoint).getUser();
      }
    }
  }

  const allContacts = {
    ...compassItems,
    ...addressBookItems,
  };
  const contacts = sortByProperty<IContact, string>(
    Object.values(allContacts).filter((contact) => {
      // NOTE: remove main call caller from contacts list
      if (
        transferCallUser &&
        contact.type === ContactType.compass &&
        contact.id === transferCallUser.id
      ) {
        return false;
      }
      return !(
        contact.type === ContactType.compass && contact.id === authUser.id
      );
    }),
    "name",
    sortIgnoreCaseComparator
  ).filter((item) => !!item.phones.length);
  let userCanAnswerCalls = false;
  const pinnedContacts = contactsPinned.filter(
    (item) => !!(allContacts[item] && allContacts[item].phones.length)
  );

  if (authphone) {
    userCanAnswerCalls =
      authphone.capabilities.includes(PhoneCapability.answer) &&
      canAuthenticatedUserUseFeature(UserFeature.callcontrol);
  }

  const activeCalls = callsItems.filter((item) => {
    return (
      item.id !== desiredTransferCallId &&
      (item.state === CallState.answered || item.state === CallState.on_hold) &&
      !callsMetadata[item.id].listeningToCall
    );
  });

  const onNotificationShow = (
    notification:
      | INotification
      | Pick<INotification, "message" | "autoDismiss" | "dismissable" | "level">
  ) => dispatch(notificationShow(notification));
  const onResetDesiredTransferCall = () => dispatch(resetDesiredTransferCall());
  const onRedirectCall = (callId: string, destination: string) =>
    dispatch<any>(redirectCall(callId, destination));
  const onStartAttendedTransfer = (destination: string) =>
    dispatch<any>(startAttendedTransfer(destination));
  const onMergeCalls = (callId1: Call["id"], callId2: Call["id"]) =>
    dispatch<any>(mergeCalls(callId1, callId2));
  const onRegisterAttendedTransfer = (
    callId1: Call["id"],
    callId2: Call["id"]
  ) => dispatch(registerAttendedTransfer(callId1, callId2));
  const onCancelAttendedTransferDestination = () =>
    dispatch(cancelAttendedTransferDestination());

  const $getContent = () => {
    if (showDialer) {
      return $getDialer();
    }
    return getContacts();
  };
  const getContacts = () => {
    if (!$contentWrap.current) {
      return null;
    }

    let contactsToDisplay = contacts;
    let activeCallsToDisplay = activeCalls.filter(
      (call) => getUserSide(call, user) !== null
    );

    if (searchQuery) {
      contactsToDisplay = filterContacts(contactsToDisplay, searchQuery);
      activeCallsToDisplay = activeCallsToDisplay.filter((call) => {
        return stringContains(
          callsMetadata[call.id].lastConnectedTitle,
          searchQuery
        );
      });
    }

    let $items: React.ReactElement[] = [];

    if (activeCallsToDisplay.length) {
      $items.push(
        <div
          key="active-calls-label"
          className="transfer-modal__contacts-label"
        >
          Current calls
        </div>
      );
      $items = $items.concat(activeCallsToDisplay.map($getCallItem));
    }

    contactsToDisplay = sortContacts(contactsToDisplay);

    if (contactsToDisplay.length) {
      $items.push(
        <div key="contacts-label" className="transfer-modal__contacts-label">
          Contacts
        </div>
      );

      // Correct: filter out nulls before concat
      const contactItems = contactsToDisplay
        .map($getContactItems)
        .flatMap((item) => item)
        .filter((item): item is React.ReactElement => item !== null);

      $items = $items.concat(contactItems);
    } else {
      $items.push(
        <ListEmptyMsg>
          {!contacts.length
            ? "You currently have no contacts."
            : `No contacts with "${searchQuery}" found.`}
        </ListEmptyMsg>
      );
    }

    return (
      <div className="transfer-modal__contacts">
        <Infinite
          containerHeight={$contentWrap.current.clientHeight}
          elementHeight={COMPACT_LIST_ITEM_HEIGHT}
          preloadAdditionalHeight={Infinite.containerHeightScaleFactor(2)}
        >
          {$items}
        </Infinite>
      </div>
    );
  };
  const isContactPinned = (contact: IContact): boolean => {
    return !!pinnedContacts.find((item) => item === contact.id);
  };

  const toggleDialler = () => {
    setShowDialer(!showDialer);
  };

  const onItemClick = (itemId: string, e: React.MouseEvent) => {
    if (onboardingMode) {
      return;
    }
    if (activeItem === itemId) {
      e.stopPropagation();
      return;
    }
    if (activeItem) {
      return;
    }
    setActiveItem(itemId);
  };

  const onModalClick = (e: React.MouseEvent) => {
    if (onboardingMode || !activeItem) {
      return;
    }
    if (waitingForConsultationCall) {
      onCancelAttendedTransferDestination();
    }
    setActiveItem(undefined);
    setWaitingForConsultationCall(false);
  };

  const $getContactItems = (
    contact: IContact,
    contactIdx: number
  ): Array<React.ReactElement | null> => {
    let userCallState: UserStatus = UserStatus.loggedOut;
    if (contact.type === ContactType.compass && users[contact.id]) {
      try {
        userCallState = getUserStatusInfo(users[contact.id]).userStatus;
      } catch (error) {
        console.warn("Error getting user status:", error);
        userCallState = UserStatus.loggedOut;
      }
    }
    return contact.phones.map((phone, idx) => {
      const itemId = `transfer-modal__contact-item-${contact.id}-${idx}`;
      let isActive = activeItem === itemId;
      if (onboardingMode) {
        isActive = idx === 0 && contactIdx === 0;
      }
      return (
        <TransferDestinationItem
          key={itemId}
          id={itemId}
          click={onItemClick}
          active={isActive}
          title={contact.name}
          number={phone.value}
          status={userCallState}
          directTransferClick={onDirectTransfer}
          directTransferDisabled={callsIsLoading || onboardingMode}
          attendedTransferClick={onAttendedTransfer}
          attendedTransferDisabled={callsIsLoading}
          isPinned={isContactPinned(contact)}
          showOnboarding={idx === 0 && contactIdx === 0}
          elementId={cssSafeStr(itemId)}
          showDialerCallCTA={
            isActive && waitingForConsultationCall && !userCanAnswerCalls
          }
        />
      );
    });
  };

  const $getCallItem = (call: Call): React.ReactElement => {
    // NOTE: this component contains only user's call
    // so `getUserSide` will always return value
    const selfPoint = getUserSide(call, user) as Side;
    const otherPoint = call.getEndpoint(OtherSide(selfPoint));
    const callTitle = callsMetadata[call.id].lastConnectedTitle;
    const callNumber = callsMetadata[call.id].lastConnectedNumber;
    let userCallState = UserStatus.loggedOut;
    if (otherPoint.type === CallPointType.user) {
      userCallState = getUserStatusInfo(
        (otherPoint as UserCallPoint).getUser()
      ).userStatus;
    }
    const itemId = `transfer-modal__call-item-${call.id}`;
    const isActive = activeItem === itemId;
    return (
      <TransferDestinationItem
        active={isActive}
        id={itemId}
        key={itemId}
        click={onItemClick}
        title={callTitle}
        number={callNumber}
        callId={call.id}
        status={userCallState}
        directTransferClick={onDirectTransfer}
        directTransferDisabled={callsIsLoading || onboardingMode}
        attendedTransferClick={onAttendedTransfer}
        attendedTransferDisabled={callsIsLoading}
        elementId={cssSafeStr(itemId)}
      />
    );
  };

  const $getDialer = () => {
    return (
      <div className="transfer-modal__dialer-wrap">
        {waitingForConsultationCall && !userCanAnswerCalls ? (
          <div className="transfer-modal__dialer-exclamation">
            The call you are getting is an automated call from dialler. Please
            answer it manually to continue with the attended transfer.
            <div className="transfer-modal__dialer-exclamation-btn">
              <Button
                small={true}
                onClick={onCancelAttendedTransferDestination}
                color={BridgeColor.gs800}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : null}
        <Dialer
          type={DialerType.transfer}
          dark={true}
          onDirectTransfer={onDirectTransfer}
          onAttendedTransfer={onAttendedTransfer}
        />
      </div>
    );
  };

  const onDirectTransfer = (
    destination: string,
    e?: React.MouseEvent,
    callId?: Call["id"]
  ) => {
    if (e) {
      e.stopPropagation();
    }
    if (!desiredTransferCallId) {
      return;
    }
    if (callId) {
      onMergeCalls(callId, desiredTransferCallId).then(() => {
        onResetDesiredTransferCall();
      }, handleError);
    } else {
      onRedirectCall(desiredTransferCallId, destination).then(() => {
        onNotificationShow({
          message: "The call is successfully transferred.",
          level: "success",
          autoDismiss: 3000,
        });
      }, handleError);
    }
  };

  const onAttendedTransfer = async (
    destination: string,
    e?: React.MouseEvent,
    callId?: Call["id"]
  ) => {
    if (e) {
      e.stopPropagation();
    }
    if (!desiredTransferCallId) {
      return;
    }
    if (callId) {
      onRegisterAttendedTransfer(desiredTransferCallId, callId);
    } else {
      setWaitingForConsultationCall(true);

      try {
        await onStartAttendedTransfer(destination);
      } catch (error) {
        handleError(error);
      } finally {
        setWaitingForConsultationCall(false);
      }
    }
  };

  const onSearchInputChanged = (value: string) => {
    setSearchQuery(value);
    setShowDialer(false);
  };

  const cssClasses = ["transfer-modal__wrap"];
  if (activeItem || onboardingMode) {
    cssClasses.push("transfer-modal__wrap--has-active");
  }
  const $title = (
    <div className="transfer-modal__header-title">Transfer call</div>
  );

  return (
    <div className={cssClasses.join(" ")} onClick={onModalClick}>
      <div className="transfer-modal__header">
        <div className="transfer-modal__header-left">
          {wrapOnboarding($title, OnboardingStepId.modalTransfer, {
            placement: "right",
          })}
        </div>
        <div className="transfer-modal__header-buttons">
          <Button
            onClick={toggleDialler}
            fill={showDialer ? "solid" : "clear"}
            color={showDialer ? BridgeColor.gs1000 : BridgeColor.gs200}
            disabled={waitingForConsultationCall}
            small={true}
            icononly={true}
            track={[
              TRACK_CATEGORY,
              showDialer
                ? TrackAction.transferModalDialerHide
                : TrackAction.transferModalDialerShow,
            ]}
          >
            <FontAwesomeIcon icon={faTh} />
          </Button>
          <Button
            onClick={onResetDesiredTransferCall}
            fill={"clear"}
            color={BridgeColor.gs200}
            small={true}
            icononly={true}
            track={[TRACK_CATEGORY, TrackAction.transferModalClose]}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      </div>
      <div
        className={`transfer-modal__search${
          showDialer ? " transfer-modal__search--hidden" : ""
        }`}
      >
        <SearchInput
          changed={onSearchInputChanged}
          value={searchQuery}
          alwaysOpened={true}
          expand={"full"}
          dark={true}
          autoFocus={onboardingMode ? false : true}
          disabled={onboardingMode}
        />
      </div>
      <div className="transfer-modal__container">
        <div
          className="transfer-modal__content-wrap"
          ref={(el) => {
            $contentWrap.current = el;
          }}
        >
          {$getContent()}
        </div>
      </div>
    </div>
  );
};
export default TransferModal;
